.buttonMainMenu {
  top: 3%;
  position: fixed;
  right: 3%;
  cursor: pointer;
  z-index: 6;
  border: none;
}

.buttonSupportMainMenu {
  bottom: 10%;
  position: fixed;
  right: 3%;
  cursor: pointer;
  z-index: 6;
  border: none;
}


.buttonHomeMainPage, .buttonSupport {
  width: 55px;
  height: 55px;
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #726784,
    0 0 80px #726784, 0 0 90px #726784, 0 0 100px #726784, 0 0 150px #726784;
  background-color: #ffffff;
  opacity: 0.7;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.homePageMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1600px;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  width: 1440px;
}

.advantages,
.introduction,
.prices,
.practices,
.course,
.tours,
.meditation,
.corporate,
.essentialOils,
.about,
.certificates,
.reviews,
.action {
  height: fit-content;
  margin-top: 140px;
}

.footer {
  height: fit-content;
}

@media (min-width: 346px) and (max-width: 440px) and (orientation: portrait) {
  .homePageMain {
    width: 340px;
  }

  .buttonMainMenu {
    top: 4%;
    right: 12%;
  }

  .sectionsContainer {
    width: 340px;
  }

  .advantages,
  .introduction,
  .prices,
  .practices,
  .course,
  .tours,
  .meditation,
  .corporate,
  .essentialOils,
  .about,
  .certificates,
  .reviews,
  .action,
  .footer {
    margin-top: 60px;
  }
}

@media (min-width: 640px) and (max-width: 900px) and (orientation: portrait) {
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .homePageMain {
    width: 940px;
  }

  .sectionsContainer {
    width: 940px;
  }

  .advantages,
  .introduction,
  .prices,
  .practices,
  .course,
  .tours,
  .meditation,
  .corporate,
  .essentialOils,
  .about,
  .certificates,
  .reviews,
  .action,
  .footer {
    margin-top: 100px;
  }
}
