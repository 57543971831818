.pricesTitleBox {
  display: flex;
  width: 100%;
}

.titlePrices {
  margin: 0 0 0 380px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.titlePricesColor {
  color: #62def0;
}

.pricesContainer {
  display: flex;
}

.carousel-container {
  width: 1440px;
  margin-top: 40px;
}

.priceCard {
  width: 348px !important;
  height: 400px;
  background: #acd6ed20;
  margin-left: 16px;
  color: #fefaed;
}

.priceCard:nth-child(2n + 1) {
  margin-top: 170px;
}

.priceCard:nth-child(4n) {
  color: #1d1d1d;
  background: #62def0;
}

.priceCard:nth-child(4n) .buttonInPriceCard {
  background: #667280 !important;
  color: #fefaed !important;
}

.priceCard:nth-child(4n) .priceCardFirstText {
  color: #1d1d1d;
}

.priceCard:nth-child(4n) .priceCardSecondText {
  color: #1d1d1d;
}

.priceCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.priceCardText {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.priceCardTitle {
  font-size: 32px;
}

.priceCardFirstText,
.priceCardSecondText {
  font-size: 18px;
  color: #fefaed80;
  margin-top: 20px;
}

.priceCardSecondText {
  margin-top: 10px;
}

.buttonInPriceCardContainer {
  margin: 0 10px 0px 10px;
  display: flex;
  flex-direction: column;
}

.priceCardThirdText {
  margin: 10px;
  font-size: 10px;
}

.buttonInPriceCard {
  height: 40px;
  background: #62def0 !important;
  border-radius: 30px !important;
  color: #1d1d1d !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .pricesTitleBox {
    flex-direction: column;
  }

  .titlePrices {
    margin: 20px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .carousel-container {
    width: 340px;
  }

  .priceCard {
    width: 260px !important;
    height: 300px;
    margin-left: 10px;
  }

  .priceCard:first-child {
    margin-left: 0px;
  }

  .priceCard:nth-child(2n + 1) {
    margin-top: 0px;
  }

  .priceCardText {
    padding: 10px;
  }

  .priceCardTitle {
    font-size: 20px;
    width: 80%;
  }

  .priceCardFirstText,
  .priceCardSecondText {
    font-size: 14px;
  }

  .buttonInPriceCardContainer {
    margin: 0 10px 10px 10px;
  }

  .buttonInPriceCard {
    font-size: 14px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titlePrices {
    margin: 0 0 0 142px;
    font-size: 32px;
    line-height: 32px;
  }

  .carousel-container {
    width: 940px;
  }

  .priceCard {
    width: 293px !important;
  }

  .priceCardText {
    padding: 10px;
  }

  .priceCardTitle {
    font-size: 24px;
  }

  .priceCardFirstText,
  .priceCardSecondText {
    font-size: 16px;
  }

  .buttonInPriceCardContainer {
    margin: 0 10px 10px 10px;
  }

  .buttonInPriceCard {
    font-size: 16px !important;
  }
}
